import { publish } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

class VariantSelects extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('change', this.onVariantChange);
  }

  /* Gets the product form ID associated with this variant selector*/
  getProductFormId() {
    // First try to get the form ID from the input's form attribute (most reliable)
    const formIdFromInput = this.querySelector('input')?.getAttribute('form');
    if (formIdFromInput) return formIdFromInput;

    // Fall back to closest form ID
    const closestFormId = this.closest('form')?.id;
    if (closestFormId) return closestFormId;

    // Last resort: use section ID from dataset
    return this.dataset.section;
  }

  onVariantChange() {
    this.updateOptions();
    this.updateMasterId();
    this.toggleAddButton(true, '', false);
    this.updatePickupAvailability();
    this.removeErrorMessage();
    this.updateVariantInput();
    this.updateVariantStatuses();

    if (this.currentVariant) {
      this.updateURL();
      this.updateVariantInput();
      this.renderProductInfo();
      this.updateShareUrl();
      this.updateOptions();

      // Directly update button based on variant availability without waiting for renderProductInfo
      this.toggleAddButton(
        !this.currentVariant.available,
        !this.currentVariant.available ? window.variantStrings.notify || 'Notify Me' : '',
        true
      );

      // Explicitly publish variant change event to ensure product-form is aware
      publish(PUB_SUB_EVENTS.variantChange, {
        data: {
          variant: this.currentVariant,
          productFormId: this.getProductFormId()
        }
      });
    }
  }

  updateOptions() {
    this.options = Array.from(this.querySelectorAll('select'), (select) => select.value);
  }

  updateMasterId() {
    this.currentVariant = this.getVariantData().find((variant) => {
      return !variant.options
        .map((option, index) => {
          return this.options[index].toLowerCase() === option.toLowerCase();
        })
        .includes(false);
    });
  }

  updateURL() {
    if (!this.currentVariant || this.dataset.updateUrl === 'false') return;
    window.history.replaceState({}, '', `${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateShareUrl() {
    const shareButton = document.getElementById(`Share-${this.dataset.section}`);
    if (!shareButton || !shareButton.updateUrl) return;
    shareButton.updateUrl(`${window.shopUrl}${this.dataset.url}?variant=${this.currentVariant.id}`);
  }

  updateVariantInput() {
    const productForm = this.closest(`form`);
    const input = productForm.querySelector('input[name="id"]');
    if (this.currentVariant) {
      input.value = this.currentVariant.id;
    }
    input.dispatchEvent(new Event('change', { bubbles: true }));
  }

  updateVariantStatuses() {
    const selectedOptionOneVariants = this.variantData.filter(
      (variant) => this.querySelector(':checked').value.toLowerCase() === variant.option1.toLowerCase()
    );
    const inputWrappers = [...this.querySelectorAll('.product-form__input')];
    inputWrappers.forEach((option, index) => {
      if (index === 0) return;
      const optionInputs = [...option.querySelectorAll('input[type="radio"], option')];
      const previousOptionSelected = inputWrappers[index - 1].querySelector(':checked').value;
      const availableOptionInputsValue = selectedOptionOneVariants
        .filter(
          (variant) =>
            variant.available && variant[`option${index}`].toLowerCase() === previousOptionSelected.toLowerCase()
        )
        .map((variantOption) => variantOption[`option${index + 1}`]);
      this.setInputAvailability(optionInputs, availableOptionInputsValue);
    });
  }

  setInputAvailability(listOfOptions, listOfAvailableOptions) {
    listOfOptions.forEach((input) => {
      if (listOfAvailableOptions.includes(input.getAttribute('value').toLowerCase())) {
        input.innerText = input.getAttribute('value');
      } else {
        input.innerText = window.variantStrings.unavailable_with_option.replace('[value]', input.getAttribute('value'));
      }
    });
  }

  updatePickupAvailability() {
    const pickUpAvailability = document.querySelector('pickup-availability');
    if (!pickUpAvailability) return;

    if (this.currentVariant && this.currentVariant.available) {
      pickUpAvailability.fetchAvailability(this.currentVariant.id);
    } else {
      pickUpAvailability.removeAttribute('available');
      pickUpAvailability.innerHTML = '';
    }
  }

  removeErrorMessage() {
    const section = this.closest('section');
    if (!section) return;

    const productForm = section.querySelector('product-form');
    if (productForm) productForm.handleErrorMessage();
  }

  renderProductInfo() {
    const requestedVariantId = this.currentVariant.id;
    const sectionId = this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section;

    fetch(
      `${this.dataset.url}?variant=${requestedVariantId}&section_id=${
        this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section
      }`
    )
      .then((response) => response.text())
      .then((responseText) => {
        // prevent unnecessary ui changes from abandoned selections
        if (this.currentVariant.id !== requestedVariantId) return;

        const html = new DOMParser().parseFromString(responseText, 'text/html');
        const destination = document.getElementById(`price-${this.dataset.section}`);
        const source = html.getElementById(
          `price-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const skuSource = html.getElementById(
          `Sku-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const skuDestination = document.getElementById(`Sku-${this.dataset.section}`);
        const inventorySource = html.getElementById(
          `Inventory-${this.dataset.originalSection ? this.dataset.originalSection : this.dataset.section}`
        );
        const inventoryDestination = document.getElementById(`Inventory-${this.dataset.section}`);

        if (source && destination) destination.innerHTML = source.innerHTML;
        if (inventorySource && inventoryDestination) inventoryDestination.innerHTML = inventorySource.innerHTML;
        if (skuSource && skuDestination) {
          skuDestination.innerHTML = skuSource.innerHTML;
          skuDestination.classList.toggle('visibility-hidden', skuSource.classList.contains('visibility-hidden'));
        }

        const price = document.getElementById(`price-${this.dataset.section}`);

        if (price) price.classList.remove('visibility-hidden');

        if (inventoryDestination)
          inventoryDestination.classList.toggle('visibility-hidden', inventorySource.innerText === '');

        const addButtonUpdated = html.getElementById(`ProductSubmitButton-${sectionId}`);
        const isUnavailable = !this.currentVariant.available;

        // Update button based on current variant availability
        this.toggleAddButton(isUnavailable, isUnavailable ? window.variantStrings.notify || 'Notify Me' : '', true);

        // When product content changes (especially with color swatches),
        // we need to ensure we get the fresh product form ID, as it might have changed
        // Get the latest product form ID after DOM updates
        const updatedProductFormId = this.getProductFormId();

        publish(PUB_SUB_EVENTS.variantChange, {
          data: {
            sectionId,
            html,
            variant: this.currentVariant,
            productFormId: updatedProductFormId
          }
        });
      });
  }

  toggleAddButton(disable = true, text, modifyClass = true, preorder = false) {
    const productFormId = this.getProductFormId();
    const productForm = document.querySelector(`#${productFormId}`);

    if (!productForm) return;
    const addButton = productForm.querySelector('[name="add"]');
    const addButtonText = productForm.querySelector('[name="add"] > span');
    if (!addButton) return;

    preorder = addButton.hasAttribute('preorder');

    if (disable) {
      addButton.setAttribute('sold-out', 'true');
      if (text) {
        addButtonText.textContent = text;

        // Make sure button is correctly marked as a BIS trigger
        addButton.classList.add('klaviyo-bis-trigger');

        // Change button type from submit to button to prevent form submission
        addButton.setAttribute('type', 'button');
      }
    } else {
      addButton.removeAttribute('sold-out');
      addButton.classList.remove('klaviyo-bis-trigger');

      // Reset button to submit type for normal add to cart functionality
      addButton.setAttribute('type', 'submit');

      if (preorder) {
        addButtonText.textContent = window.variantStrings.preOrder || 'Pre-Order';
      } else {
        addButtonText.textContent = window.variantStrings.addToCart || 'Add to Cart';
      }
    }

    if (!modifyClass) return;
  }

  // setUnavailable() {
  //   const productFormId = this.getProductFormId();
  //   const button = document.getElementById(`AddToCart-${productFormId}`);
  //   const addButton = button.querySelector('[name="add"]');
  //   const addButtonText = button.querySelector('[name="add"] > span');
  //   const price = document.getElementById(`price-${productFormId}`);
  //   const inventory = document.getElementById(`Inventory-${productFormId}`);
  //   const sku = document.getElementById(`Sku-${productFormId}`);

  //   if (!addButton) return;
  //   addButtonText.textContent = window.variantStrings.unavailable || 'Unavailable';

  //   // Add Klaviyo BIS trigger class when unavailable
  //   addButton.classList.add('klaviyo-bis-trigger');

  //   if (price) price.classList.add('visibility-hidden');
  //   if (inventory) inventory.classList.add('visibility-hidden');
  //   if (sku) sku.classList.add('visibility-hidden');
  // }

  getVariantData() {
    this.variantData = JSON.parse(this.querySelector('[type="application/json"]').textContent);
    return this.variantData;
  }
}

customElements.define('variant-selects', VariantSelects);

class VariantRadios extends VariantSelects {
  constructor() {
    super();
  }

  setInputAvailability(listOfOptions, listOfAvailableOptions) {
    listOfOptions.forEach((input) => {
      if (listOfAvailableOptions.includes(input.getAttribute('value'))) {
        input.classList.remove('disabled');
      } else {
        input.classList.add('disabled');

        // When a disabled option is selected, update button state
        if (input.checked) {
          const form = this.closest('form');
          if (form) {
            const productForm = document.querySelector(`product-form[id="${form.id}-product-form"]`);
            if (productForm) {
              const button = productForm.querySelector('[name="add"]');
              const buttonText = button.querySelector('span');

              if (button && buttonText) {
                button.setAttribute('sold-out', 'true');
                button.classList.add('klaviyo-bis-trigger');
                button.setAttribute('type', 'button');
                buttonText.textContent = window.variantStrings.notify || 'Notify Me';
              }
            }
          }
        }
      }
    });
  }

  updateOptions() {
    const fieldsets = Array.from(this.querySelectorAll('fieldset'));
    this.options = fieldsets.map((fieldset) => {
      const radio = Array.from(fieldset.querySelectorAll('input')).find((radio) => radio.checked);
      return radio ? radio.value : '';
    });
  }
}

customElements.define('variant-radios', VariantRadios);
